const TOKEN = "token";
const USER = "user";
const LOADING = false;


const internalStorage = window.localStorage;

const setLoading = (value) => {
    internalStorage.setItem(LOADING, value);
}

const getLoading = () => {
    return internalStorage.getItem(LOADING);
}

const setToken = (token) => {
    internalStorage.setItem(TOKEN, token);
}
const getToken = () => {
    return internalStorage.getItem(TOKEN);
}


const clear = () => {
    internalStorage.setItem(TOKEN, null);
    internalStorage.setItem(USER, null);
    internalStorage.clear();
}



const setUser = (user) => {
    if (user) {
        internalStorage.setItem(USER, JSON.stringify(user));
    }
}

const getUser = () => {
    const value = internalStorage.getItem(USER);
    return value ? JSON.parse(value) : null;
}

export default {
    clear,
    setLoading,
    getLoading,
    setToken,
    getToken,
    setUser,
    getUser,
}