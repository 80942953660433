import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Construction } from "@mui/icons-material";
import { Box, Card, CardContent, CardActions, Grid, Typography, IconButton, Button } from "@mui/material";

import theme from "../theme";

import BasePage from "../../../../components/page_base/PageBase";

import { loadProfessionsWithCount } from "../../profession.reducer";
import HeadPage from "../../../../components/title/Title";

const ListProfessionsPage = () => {
    const professionsState = useSelector((state) => state.professionsState);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        dispatch(loadProfessionsWithCount()).then(() => {
            setLoading(false);
        });
    }, [dispatch]);

    const page = (
        <Box sx={{ padding: theme.spacing(4) }}>
            <HeadPage
                title={"Lista de Profissões"}
            />
            {/* <Typography variant="h3" component="h2" sx={{ marginBottom: theme.spacing(2), textAlign: 'center' }}>
                Lista de Profissões
            </Typography> */}
            <Grid container spacing={4}>
                {professionsState.professions && professionsState.professions.map((profession) => (
                    <Grid item xs={12} sm={6} md={3} key={profession.id}>
                        <Card sx={{
                            borderRadius: theme.shape.borderRadius,
                            border: `1px solid ${theme.palette.divider}`,
                            padding: theme.spacing(2),
                            textAlign: 'center',
                            boxShadow: theme.shadows[3],
                            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: theme.shadows[6],
                                backgroundImage: 'linear-gradient(to bottom right, #f5f5f5, #e0e0e0)',
                                color: theme.palette.text.primary,
                            },
                        }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Link to={`/search-professionals?profession=${profession.name}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography variant="h6" gutterBottom>{profession.name}</Typography>
                                </Link>
                                <Box sx={{ marginBottom: theme.spacing(1) }}>
                                    <IconButton>
                                        {/* <BuildTwoTone /> */}
                                        < Construction />
                                    </IconButton>
                                </Box>
                                {/* <Typography variant="body2" color="textSecondary">
                                    {profession.count > 1 ? `${profession.count} profissionais` : `${profession.count} profissional`}
                                </Typography> */}
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Button
                                    size="small"
                                    color="primary"
                                    component={Link}
                                    to={`/search-professionals?profession=${profession.name}`}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Ver mais
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default ListProfessionsPage;
