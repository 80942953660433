import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Pagination, Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const DataTable = ({ data, page, setPage, itemsPerPage, handleItemsPerPageChange, renderActions = null }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Obtenha os nomes das colunas dinamicamente
    const columnNames = data && data.length > 0 ? Object.keys(data[0]) : [];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const filteredData = data && data.filter(item =>
        Object.values(item).some(value =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredData ? filteredData.slice(startIndex, endIndex) : [];

    return (
        <div>
            <Typography variant="h5" align="center" gutterBottom>
                {filteredData && filteredData.length === 0 ? 'Nenhum registro encontrado' : `Resultados da Pesquisa (${filteredData ? filteredData.length : 0} registros)`}
            </Typography>
            <Box boxShadow={3} borderRadius={5} p={2} mb={2} style={{ backgroundColor: 'white' }}>
                <TextField
                    label="Pesquisar"
                    variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columnNames.map((columnName) => (
                                <TableCell key={columnName} variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                                    {columnName.charAt(0).toUpperCase() + columnName.slice(1)}
                                </TableCell>
                            ))}
                            {renderActions && (
                                <TableCell variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>Ações</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData && paginatedData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columnNames && columnNames.length} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                        ) : (
                            paginatedData && paginatedData.map((row, index) => (
                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                    {columnNames.map((columnName) => (
                                        <TableCell key={columnName}>{row[columnName]}</TableCell>
                                    ))}
                                    {renderActions && (
                                        <TableCell>{renderActions(row)}</TableCell>
                                    )}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={3} mb={3} marginBottom={6}>
                <FormControl variant="outlined" style={{ minWidth: 125 }}>
                    <InputLabel>Linhas por página</InputLabel>
                    <Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        label="Linhas por página"
                    >
                        {[10, 20, 50, 100, 200].map((rowsPerPageOption) => (
                            <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                                {rowsPerPageOption}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Pagination
                    count={Math.ceil(filteredData && filteredData.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </div>
    );
};

export default DataTable;
