import { createSlice } from "@reduxjs/toolkit";
import api from "../../services/api.service";
import storage from "../../store/storage";
import { URL_API } from "../../constants/constants";



const initialState = {
    budgets: [],
    total: 0,
    pageSize: 25,
    currentPage: 1,
    errorMessage: "",
    successMessage: "",
}

const BudgetSlice = createSlice({
    name: "budgetState",
    initialState,
    reducers: {
        setBudgets(state, { payload }) {
            const budgets = payload;
            return { ...state, budgets };
        },
        setCurrentPage: (state, { payload }) => {
            const currentPage = payload;
            return { ...state, currentPage }
        },
        setTotalBudgets: (state, { payload }) => {
            const total = payload;
            return { ...state, total }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
        removeBudget(state, { payload }) {
            const budgets = state.budgets.filter(budget => budget.id !== payload);
            return { ...state, budgets }
        },
    }
});

export const {
    setBudgets,
    removeBudget,
    setCurrentPage,
    setTotalBudgets,
    setErrorMessage,
    setSuccessMessage,
} = BudgetSlice.actions;

export const loadBudgetByMonth = (month, page = 1, pagesize = 10) => (dispatch) => {
    try {
        const user = storage.getUser();
        const data = {
            month,
            page,
            pagesize,
            professionalID: user.id,
        }

        const URI = `${URL_API}/budgets/month`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setBudgets(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setBudgets([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa dos orçamentos."));
            })
    } catch (error) {
        console.log({ error })
    }
}


export const loadAllBudgets = (page = 1, pageSize = 50) => (dispatch) => {
    try {
        const offset = (page - 1) * pageSize;

        const URI = `${URL_API}/budgets?limit=${pageSize}&offset=${offset}`;
        return fetch(URI, {
            method: 'GET',
            headers: api.getHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setTotalBudgets(data.total));
                dispatch(setBudgets(data.budgets));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setBudgets([]));
                dispatch(setTotalBudgets(0));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa dos orçamentos."));
            })
    } catch (error) {
        console.log({ error })
    }
}

export const getBudgetByEmail = (email) => (dispatch) => {
    try {
        const URI = `${URL_API}/budget/email`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify({ email })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setBudgets([data]));
                dispatch(setTotalBudgets(0));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setBudgets([]));
                dispatch(setTotalBudgets(0));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa dos orçamentos por email."));
            })
    } catch (error) {
        console.log({ error })
    }
}


export const deleteBudget = (id) => (dispatch) => {
    try {
        const idNumber = Number(id);
        const URI = `${URL_API}/budget/${idNumber}`;
        return fetch(URI, {
            method: 'DELETE',
            headers: api.getHeaders(),
        })
            .then(() => {
                dispatch(removeBudget(id));
                dispatch(setSuccessMessage("Orçamento removido com sucesso."));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setErrorMessage("Ops, Houve um erro ao remover o orçamentos."));
            })
    } catch (error) {
        console.log({ error })
    }
}




export default BudgetSlice.reducer;