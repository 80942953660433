import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, TextField } from '@mui/material';

import { save, loadProfessionByID, setErrorMessage, setSuccessMessage } from '../../profession.reducer';

import BasePage from '../../../../components/page_base/PageBase';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';
import HeadPage from '../../../../components/title/Title';

import theme from "../theme";

const FormProfession = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const professionState = useSelector((state) => state.professionsState);
    const profession = professionState.profession || null;

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState('');


    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(loadProfessionByID(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (profession && id) {
            const { name, description } = profession;
            setName(name);
            setDescription(description);
        }
    }, [profession, id]);


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const onSubmitForm = () => {
        if (!id) {
            if (name && description) {
                dispatch(save({ name, description }))
                    .then(() => {
                        setLoading(false);
                        clearFields();
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                dispatch(setErrorMessage("Todos os campos são obrigatórios."));
            }
        } else {
            dispatch(save({ id, name, description }))
                .then(() => {
                    setLoading(false);
                    clearFields();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };


    const clearFields = () => {
        setName('');
        setDescription('');
    };

    const page = (
        <>
            <HeadPage title={id ? "Editar Profissão" : "Cadastrar Profissão"} />

            {professionState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={professionState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {professionState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={professionState.successMessage}
                    onClose={onCloseMessage}
                />
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} mb={8}>
                <TextField
                    fullWidth
                    name="name"
                    label="Nome da Profissão"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <TextField
                    fullWidth
                    name="description"
                    label="Descrição"
                    variant="outlined"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                    multiline
                    rows={4}
                />

                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={onSubmitForm}
                >
                    {id ? "Atualizar Profissão" : "Cadastrar Profissão"}
                </Button>

            </Box>
        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
};

export default FormProfession;
