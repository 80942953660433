import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

import BasePage from '../../../components/page_base/PageBase';
import Carrossel from '../../../components/carrossel/Carrosel';
import ImageWithText from '../../../components/image-with-text/ImageWithText';
import ListExpostitors from '../../../components/expositor/ListExpositor';
import IncentiveMessage from '../../../components/incentive-message/IncentiveMessage';
import ListSixProfessions from '../../../components/list-professions/ListProfessions';
import AnuncieAqui from '../../../assets/2.jpeg';
import EncontreProfissional from '../../../assets/1.jpeg';
import SeusClientes from '../../../assets/3.jpeg';
import TambemVerao from '../../../assets/4.jpeg';
// import AnuncieAquiMobile from '../../../assets/mobile2.jpeg';
// import EncontreProfissionalMobile from '../../../assets/mobile1.jpeg';
import SeusClientesMobile from '../../../assets/mobile3.jpeg';
import TambemVeraoMobile from '../../../assets/mobile4.jpeg';
import React from 'react';
import './home.css';




const HomePage = () => {

  const isMobile = useMediaQuery('(max-width:600px)');

  const carouselItems = isMobile
    ? [
      // {
      //   img: EncontreProfissionalMobile,
      //   title: 'Encontre o profissional que você precisa',
      //    description: 'Encontre o profissional que você precisa',  

      // },
      // {
      //   img: AnuncieAquiMobile,
      //   title: 'Anuncie sua empresa conosco',
      //   description: 'Anuncie sua empresa conosco',
      // },
      {
        img: SeusClientesMobile,
        title: 'Anuncie sua empresa conosco',
        description: 'Anuncie sua empresa conosco',
      },
      {
        img: TambemVeraoMobile,
        title: 'Anuncie sua empresa conosco',
        description: 'Anuncie sua empresa conosco',
      },
    ]
    : [
      {
        img: EncontreProfissional,
        title: 'Encontre o profissional que você precisa',
        description: 'Encontre o profissional que você precisa',
      },
      {
        img: AnuncieAqui,
        title: 'Anuncie sua empresa conosco',
        description: 'Anuncie sua empresa conosco',
      },
      {
        img: SeusClientes,
        title: 'Encontre o profissional que você precisa',
        description: 'Encontre o profissional que você precisa',
      },
      {
        img: TambemVerao,
        title: 'Encontre o profissional que você precisa',
        description: 'Encontre o profissional que você precisa',
      },
    ];

  const expositors = [
    {
      logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
      name: 'Empresa A',
      description: 'Descrição da Empresa A.',
    },
    {
      logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
      name: 'Empresa B',
      description: 'Descrição da Empresa B.',
    },
    {
      logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
      name: 'Empresa C',
      description: 'Descrição da Empresa C.',
    },
  ];

  {/* Estilos globais para o botão do WhatsApp */ }
  <style>
    {`
      .whatsapp-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 9999;
          // border: 1px solid red;
          display: block !important;
      }
      .whatsapp-button img {
          width: 160px;
          height: 160px;
      }
      @media (max-width: 480px) {
          .whatsapp-button img {
              width: 80px; /* Tamanho para telas muito pequenas */
              height: 80px; /* Tamanho para telas muito pequenas */
          }
      }
      @media (max-width: 360px) {
          .whatsapp-button img {
              width: 60px; /* Tamanho para telas super pequenas */
              height: 60px; /* Tamanho para telas super pequenas */
          }
      }
  `}
  </style>

  return (

    <div className="home-container">
      {/* HEADER */}
      <header className="home-header">
        <Carrossel carouselItems={carouselItems} isMobile={isMobile} />
      </header>


      {/* MAIN CONTENT */}
      <main>
        {/* FIND PROFESSIONALS SECTION */}
        <section className="find-professionals">
          <div className="find-text">
            <h1>Encontre Profissionais</h1>
            <p>Transforme sua experiência com orçamentos para as reformas mais eficientes com o Contruir Mais Barato. Os melhores profissionais para seu projeto!</p>
            <Link to="/search-professionals" className="find-professionals-button">
              Pesquisar agora
            </Link>
          </div>
          <div className="images">
            <img src="/images/first.jpeg" alt="Profissionais trabalhando" />
          </div>
        </section>

        {/* PROFISSIONAIS QUE VOCÊ PODE ENCONTRAR */}
        <section className="professionals">
          <h2>Profissionais que você pode encontrar</h2>
          <div className="professionals-list">
            {["Eletricista", "Encanador", "Arquiteto", "Pintor", "Marceneiro", "Pedreiro", "Empreiteiro", "Engenheiro Civil", "refrigeração / ar condicionado", "Carpinteiro", "Gesseiro", "Mestre de obras", "Ajudante de Obra / Servente", "Calheiro", "Vidraceiro", "Marido de aluguel", "Energia solar", "Piscineiro", "Azulejista", "serralheiro",].map(profissao => (
              <div className="professional-card" key={profissao}>
                <h3>{profissao}</h3>
              </div>
            ))}
          </div>
        </section>

        {/* INVESTIR NA ESCOLHA CORRETA */}
        <section className="invest">
          <div className="invest-text">
            <h2>Investir na escolha correta é economizar tempo e dinheiro</h2>
            <p>Contratar o profissional adequado para a sua reforma faz toda a diferença no resultado final.</p>
          </div>
          <div className="invest-options">
            <div className="option">
              <img src="./images/reconhecido.jpeg" alt="Profissional desenhando projeto" />
              <p>Seja reconhecido e esteja à frente. Receba propostas de orçamento.</p>
              <Link to="/search-professionals" className="option-button">Pesquisar Profissional</Link>
            </div>
            <div className="option">
              <img src="./images/casa.jpeg" alt="Casa reformada" />
              <p>Precisa construir ou reformar? Solicite um orçamento pelo Construir Mais Barato!</p>
              <Link to="/search-professionals" className="option-button">Pesquisar Profissional</Link>
            </div>
          </div>
        </section>

        {/* ARTIGOS SOBRE REFORMA */}
        <section className="articles">
          <h2>Artigos sobre Reforma</h2>
          <p>
            Leia os artigos mais recentes para se manter atualizado sobre as melhores
            práticas e dicas para a sua reforma.
          </p>
          <div className="article-list">
            {[
              {
                id: 1,
                title: "Como Planejar Sua Reforma",
                description:
                  "Descubra as etapas essenciais para planejar sua reforma sem dores de cabeça.",
                image: "./images/artigos1.jpeg",
              },
              {
                id: 2,
                title: "Escolha de Materiais",
                description:
                  "Saiba como escolher os materiais ideais para o seu projeto, economizando e garantindo qualidade.",
                image: "./images/artigos2.jpeg",
              },
              {
                id: 3,
                title: "Contratação de Profissionais",
                description:
                  "Aprenda a contratar os melhores profissionais para sua obra e evite problemas futuros.",
                image: "./images/artigos3.jpeg",
              },
            ].map(article => (
              <div className="article-card" key={article.id}>
                <img src={article.image} alt={article.title} />
                <div className="article-content">
                  <h3>{article.title}</h3>
                  <p>{article.description}</p>
                  <div className="article-buttons">
                    <Link to="/search-professionals" className="option-button">Pesquisar Profissional</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* TESTIMONIALS SECTION */}

        <section className="testimonials">
          <h2>Depoimentos que fazem a diferença</h2>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={30}
            slidesPerView={1} // Mostra um slide por vez
            breakpoints={{
              768: { slidesPerView: 2 }, // Mostra dois slides lado a lado em telas maiores que 768px
            }}
          >
            <SwiperSlide>
              <div className="testimonial-content">
                <div className="testimonial-image">
                  <img src="./images/clientes/cliente2.jpeg" alt="Miguel Lopes" />
                </div>
                <blockquote>
                  <p>
                    “Eu estava muito inseguro em começar a reforma da minha casa,
                    principalmente por não saber por onde começar. Mas o site
                    Construir Mais Barato foi uma mão na roda! Achei rápido um
                    profissional que me ajudou desde o projeto até a reforma. Tudo
                    ficou lindo e dentro do orçamento! Recomendo muito.”
                  </p>
                  <cite>- Miguel Lopes</cite>
                </blockquote>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-content">
                <div className="testimonial-image">
                  <img src="./images/clientes/cliente1.jpeg" alt="Lucas Alex" />
                </div>
                <blockquote>
                  <p>
                    “Recomendo muito a plataforma. Tem me auxiliado a encontrar
                    profissionais de qualidade.”
                  </p>
                  <cite>- Lucas Alex</cite>
                </blockquote>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        {/*<section className="testimonials" >
            <h2>Depoimentos que fazem a diferença</h2>
            <div className="testimonial-image">
                <img src="./images/clientes/cliente2.jpeg" alt="Miguel Lopes" />
            </div>
            <blockquote>
              <p>“Eu estava muito inseguro em começar a reforma da minha casa, principalmente por não saber por onde começar. Mas o site Construir Mais Barato foi uma mão na roda! Achei rápido um profissional que me ajudou desde o projeto até a reforma. Tudo ficou lindo e dentro do orçamento! Recomendo muito.”</p>
              <cite>- Miguel Lopes</cite>
            </blockquote>
            <div className="testimonial-image">
                <img src="./images/clientes/cliente1.jpeg" alt="Lucas Alex" />
            </div>
            <blockquote>
              <p>“Recomendo muito a plataforma. Tem me auxiliado a encontrar profissionais de qualidade.”</p>
              <cite>- Lucas Alex</cite>
            </blockquote>
          </section>*/}
        <div className="whatsapp-button">
          <a href="https://api.whatsapp.com/send?phone=5514991665023&text=Olá! Gostaria de mais informações." target="_blank" rel="noopener">
            <img src="./images/btn_whatzap/btn_whatzap_6.png" alt="WhatsApp" style={{ width: '120px', height: '120px' }} />
          </a>
        </div>
      </main>
    </div>

  );
};

export default HomePage;