import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    Paper,
    Dialog,
    Button,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TextField,
    Typography,
    Pagination,
    DialogTitle,
    DialogActions,
    DialogContent,
    TableContainer,
    DialogContentText,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import BasePage from "../../../../components/page_base/PageBase";

import { loadAllProfessions, setCurrentPage, deleteProfession, setErrorMessage, setSuccessMessage } from "../../profession.reducer"; // Mudança para carregar profissões

import theme from '../theme';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';
import HeadPage from "../../../../components/title/Title";

const ADMListAllProfessions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const professionState = useSelector((state) => state.professionsState); // Mudança para estado de profissões

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedProfessionId, setSelectedProfessionId] = useState(null);

    // Simulando uma chamada para buscar profissões
    useEffect(() => {
        setLoading(true);
        dispatch(loadAllProfessions(professionState.currentPage, professionState.pageSize)).then(() => {
            setLoading(false);
        });
    }, [dispatch, professionState.currentPage, professionState.pageSize]);

    const handleSearch = (event) => {
        dispatch(setCurrentPage(0));
    };

    const handlePageChange = (event, value) => {
        dispatch(setCurrentPage(value));
    };

    const handleOpen = (id) => {
        console.log(id)
        setSelectedProfessionId(id);
        setOpen(true); // Abre o modal
    };

    const handleEdit = (id) => {
        id = Number(id);
        navigate(`/editar-profissao/${id}`);
    }

    const handleClose = () => {
        setOpen(false); // Fecha o modal
        setSelectedProfessionId(null);
    };

    const handleConfirmDelete = () => {

        if (selectedProfessionId) {
            dispatch(deleteProfession(selectedProfessionId)); // Executa a função de desativação
        }
        handleClose(); // Fecha o modal após a confirmação
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const total = isNaN(professionState.total) ? 0 : professionState.total;
    const pageSize = isNaN(professionState.pageSize) || professionState.pageSize === 0 ? 1 : professionState.pageSize;
    const count = Math.ceil(total / pageSize);


    const pageContent = (
        <>
            <HeadPage title={`Profissões cadastradas`} />

            {professionState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={professionState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {professionState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={professionState.successMessage}
                    onClose={onCloseMessage}
                />
            )}

            <TextField
                label="Pesquisar"
                variant="outlined"
                fullWidth
                onChange={handleSearch}
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>ID</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                            {/* <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Descrição</TableCell> */}
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white', textAlign: 'center' }}>Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {professionState.professions && professionState.professions.map((profession, index) => (
                            <TableRow key={profession.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <TableCell>{profession.id}</TableCell>
                                <TableCell>{profession.name.toUpperCase()}</TableCell>
                                {/* <TableCell>{profession.description}</TableCell> */}
                                <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        title={`Editar a profissão ${profession.name}`}
                                        onClick={() => handleEdit(profession.id)}
                                        sx={{ marginRight: 2 }}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        title={`Exluir a profissão ${profession.name}`}
                                        onClick={() => handleOpen(profession.id)}
                                        sx={{ marginRight: 2 }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination
                    count={count}
                    page={professionState.currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ marginTop: 16, marginBottom: 16 }}
                />
            </TableContainer>

            {/* Modal de Confirmação */}
            < Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmação de Desativação</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja desativar o profissional?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant='contained'>
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant='contained' autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {loading ? <Typography>Carregando...</Typography> : pageContent}
        </BasePage>
    );
}

export default ADMListAllProfessions;
