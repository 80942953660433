import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    marginTop: 4,
                    marginBottom: 4,
                    padding: 2,
                    border: '1px solid #6d6969',
                    borderRadius: 1,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    marginBottom: 15,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginTop: 20,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: 16,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 80,
                    height: 80,
                    backgroundColor: '#1976d2',
                },
            },
        },
    },
});

export default theme;
