
import api from "./api.service";
import storage from "../store/storage";
import { URL_PUBLICA } from "../constants/constants";


const isAuthenticated = () => {

  const token = storage.getToken();
  return token !== null && token !== undefined && token !== '' ? true : false;
};

const login = (credential) => {
  if (credential) {
    const { email, password } = credential;
    return fetch(`${URL_PUBLICA}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Erro ao fazer login');
          });
        }
        return response.json();
      })
      .then((data) => {
        const { token, user, message } = data
        if (message) {
          storage.setToken(null);
          storage.setUser(null);
          return Promise.reject(message);
        } else {
          storage.setToken(token);
          storage.setUser(user);
          return Promise.resolve(data);
        }
      })
      .catch((error) => {
        storage.setToken(null);
        storage.setUser(null);
        console.error('Request failed', error);
        throw error;
      });
  }
};

const logout = () => {
  return (dispatch) => {
    storage.setToken(null);
    storage.setUser(null);
    storage.clear();
    dispatch({ type: 'LOGOUT_SUCCESS' });
  }
};

const getUserName = () => {
  const user = storage.getUser();
  return user ? user.login : "";
};

const forgotPassword = (email) => {
  if (email) {
    // console.log("Authorization credentials => ", email);
    return api.post("/forgot-password", { email }).then((response) => {
      // const { data } = response;
      // console.log("resposta do forgot => ", data);
      return Promise.resolve(response);
    });
  }
};

export default {
  isAuthenticated,
  login,
  logout,
  getUserName,
  forgotPassword,
};
