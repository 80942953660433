import { Modal, Box, Typography, Button } from '@mui/material';

const TermResponsabilityModal = ({ open, onClose, onAccepted }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: '100%',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h3" component="h2" id="modal-title" gutterBottom>
                    Termo de Responsabilidade e Isenção de Responsabilidade por Dados Fornecidos - LGPD
                </Typography>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1" paragraph>Por favor, leia atentamente o seguinte termo antes de prosseguir:</Typography>

                    <Typography variant="body1" paragraph>
                        Eu, o Cliente, ao utilizar os serviços fornecidos pela plataforma digital CONSTRUIR MAIS BARATO, reconheço e concordo com os termos e condições estabelecidos neste documento.
                    </Typography>

                    <Typography variant="h5">Responsabilidade pelos Dados Fornecidos:</Typography>
                    <Typography variant="body1" paragraph>
                        Eu reconheço e concordo que sou totalmente responsável por quaisquer dados pessoais, informações ou conteúdos que eu solicite, receba ou de qualquer forma obtenha através da plataforma CONSTRUIR MAIS BARATO.
                    </Typography>

                    <Typography variant="h5">Isenção de Responsabilidade da Plataforma:</Typography>
                    <Typography variant="body1" paragraph>
                        A responsabilidade pela proteção e tratamento adequado dos dados pessoais é exclusivamente do Cliente.
                    </Typography>

                    <Typography variant="h5">Finalidade e Consentimento:</Typography>
                    <Typography variant="body1" paragraph>
                        Eu reconheço que a CONSTRUIR MAIS BARATO pode coletar, armazenar e utilizar meus dados pessoais conforme necessário para a prestação de serviços ou cumprimento de obrigações contratuais, desde que em conformidade com as disposições da LGPD e mediante consentimento explícito do titular dos dados, quando aplicável.
                    </Typography>

                    <Typography variant="h5">Segurança dos Dados:</Typography>
                    <Typography variant="body1" paragraph>
                        A CONSTRUIR MAIS BARATO adota medidas técnicas e organizacionais adequadas para proteger os dados pessoais contra acesso não autorizado, uso indevido, divulgação, alteração e destruição não autorizados, em conformidade com as disposições da LGPD.
                    </Typography>

                    <Typography variant="h5">Direitos dos Titulares dos Dados:</Typography>
                    <Typography variant="body1" paragraph>
                        Eu reconheço e concordo em respeitar os direitos dos titulares dos dados, conforme previsto na LGPD, incluindo o direito de acesso, retificação, exclusão, anonimização, portabilidade e revogação do consentimento.
                    </Typography>

                    <Typography variant="h5">Indenização:</Typography>
                    <Typography variant="body1" paragraph>
                        Eu concordo em indenizar e isentar a CONSTRUIR MAIS BARATO, seus diretores, funcionários e agentes de qualquer responsabilidade, perda, reclamação ou despesa (incluindo honorários advocatícios razoáveis) decorrentes ou relacionados com o tratamento de dados pessoais pelo Cliente ou com o uso da plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Ao clicar no botão "Concordo", eu confirmo que li, entendi e concordo com os termos e condições estabelecidos neste Termo de Responsabilidade e Isenção de Responsabilidade por Dados Fornecidos.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Ao clicar em "Concordo", você estará dando consentimento para os termos acima mencionados.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Este termo entra em vigor na data do clique no botão "Concordo" e permanecerá em pleno vigor e efeito durante o período de utilização dos serviços da plataforma CONSTRUIR MAIS BARATO pelo Cliente.
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={onClose} variant="contained" color="error" sx={{ mr: 2 }}>
                            Fechar
                        </Button>
                        <Button onClick={onAccepted} variant="contained" color="primary">
                            Aceito
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default TermResponsabilityModal;
