import { Routes, Route, Navigate } from "react-router-dom";

import ErrorPage from "../pages/error/ErrorPage";

import Search from "../pages/search/page/Search";
import { MyCashback } from "../pages/cashback";
import { BannerFormPage } from "../pages/banner";
import AdmDashboardPanel from "../pages/adm-controll-panel/AdmControllPanel";
import DashboardUserPanel from "../pages/dashboard-user/DashboardUserPanel";
import { ADMListAllBudgets, ListBudgets } from "../pages/budget";
import { HomePage } from "../pages/home";
import { ConfirmPasswordPage, LoginPage, PasswordResetPage } from "../pages/login";
import { FormProfession, ListProfessionsPage, ADMListAllProfessions } from "../pages/profession";
import { CountProfessionalsByProfession, FindAllProfessionals, FormProfessional, LastProfessionals, CountProfessionalsByState } from "../pages/professional";


import auth from '../services/authorization';




// Componente de rota privada
const PrivateRoute = ({ children }) => {
  return auth.isAuthenticated() ? children : <Navigate to="/login" replace />
};


const AppRoutes = () => {
  return (
    <Routes>
      {/* Rotas públicas */}
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/recuperar-senha" element={<PasswordResetPage />} />
      <Route path="/confirmar-senha" element={<ConfirmPasswordPage />} />
      <Route path="/search-professionals" element={<Search />} />
      <Route path="/add-professionals" element={<FormProfessional />} />
      <Route path="/list-professions" element={<ListProfessionsPage />} />

      {/* Rotas privadas */}
      <Route path="/my-budgets" element={<PrivateRoute> <ListBudgets /> </PrivateRoute>}></Route>
      <Route path="/my-cashback" element={<PrivateRoute> <MyCashback /> </PrivateRoute>}></Route>
      <Route path="/cadastrar-banner" element={<PrivateRoute> <BannerFormPage /> </PrivateRoute>}></Route>
      <Route path="/cadastrar-profissao" element={<PrivateRoute> <FormProfession /> </PrivateRoute>}></Route>
      <Route path="/editar-profissao/:id" element={<PrivateRoute> <FormProfession /> </PrivateRoute>}></Route>
      <Route path="/listar-profissoes" element={<PrivateRoute> <ADMListAllProfessions /> </PrivateRoute>}></Route>
      <Route path="/controll-panel" element={<PrivateRoute> <AdmDashboardPanel /> </PrivateRoute>}></Route>
      <Route path="/dashboard-user" element={<PrivateRoute> <DashboardUserPanel /> </PrivateRoute>}></Route>
      <Route path="/last-professionals" element={<PrivateRoute> <LastProfessionals /> </PrivateRoute>}></Route>
      <Route path="/listar-todos-orcamentos" element={<PrivateRoute> <ADMListAllBudgets /> </PrivateRoute>}></Route>
      <Route path="/buscarTodosProfissionais" element={<PrivateRoute> <FindAllProfessionals /> </PrivateRoute>}></Route>
      <Route path="/edit-professional/:id" element={<PrivateRoute>  <FormProfessional /> </PrivateRoute>} />
      <Route path="/contar-Profissionais-por-profissao" element={<PrivateRoute> <CountProfessionalsByProfession /> </PrivateRoute>}></Route>
      <Route path="/contar-Profissionais-por-estado" element={<PrivateRoute> <CountProfessionalsByState /> </PrivateRoute>}></Route>




      {/* Rota de erro para páginas não encontradas */}
      <Route path="*" element={<ErrorPage />} />


    </Routes>
  );
};

export default AppRoutes;
