import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
    Typography,
    Pagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";

import BasePage from "../../../../components/page_base/PageBase";

import { getBudgetByEmail, loadAllBudgets, setCurrentPage, deleteBudget, setErrorMessage, setSuccessMessage } from "../../budget.reducer";

import { parseDateString } from "../../../utils/Utils";

import theme from './theme';
import HeadPage from "../../../../components/title/Title";
import MakeMessage from "../../../../components/makeMessage/MakeMessage";



const ADMListAllBudgets = () => {
    const dispatch = useDispatch();

    const budgetState = useSelector((state) => state.budgetState);

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);
    const [idBudgetToDelete, setIdBudgetToDelete] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    // Simulando uma chamada para buscar orçamentos
    useEffect(() => {
        setLoading(true);
        dispatch(loadAllBudgets(budgetState.currentPage, budgetState.pageSize)).then(() => {
            setLoading(false);
        })
    }, [dispatch, budgetState.currentPage, budgetState.pageSize]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        dispatch(setCurrentPage(0));
    };

    const handleSearch = async () => {
        if (email) {
            setLoading(true);
            dispatch(getBudgetByEmail(email)).then(() => {
                setLoading(false);
            });
        }
    };

    const handlePageChange = (event, value) => {
        dispatch(setCurrentPage(value));
    };

    const handleSearchAllBudgets = async () => {
        setLoading(true);
        dispatch(loadAllBudgets(budgetState.currentPage, budgetState.pageSize)).then(() => {
            setEmail("");
            setLoading(false);
        })
    }

    const handleOpenDeleteModal = (id) => {
        setIdBudgetToDelete(id);
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setIdBudgetToDelete(null);
    };

    const handleDeleteBudget = async () => {
        if (idBudgetToDelete) {
            console.log({ idBudgetToDelete })
            dispatch(deleteBudget(idBudgetToDelete));
            handleCloseDeleteModal();
        }
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };


    const pageContent = (
        <>
            <HeadPage title={`Orçamentos cadastrados `} />

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <TextField
                    label="Pesquisar Orçamentos pelo Email Cadastrado"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    margin="normal"
                    style={{ marginRight: '16px' }} // Espaço entre o TextField e o Button
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    style={{ height: '56px', marginBottom: '20px', marginRight: "10px" }} // Garantir que a altura do Button seja a mesma do TextField
                >
                    Pesquisar
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearchAllBudgets}
                    style={{ height: '56px', marginBottom: '20px' }} // Garantir que a altura do Button seja a mesma do TextField
                >
                    Pesquisar Todos os Orçamentos
                </Button>
            </div>

            {budgetState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={budgetState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {budgetState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={budgetState.successMessage}
                    onClose={onCloseMessage}
                />
            )}


            <TableContainer component={Paper}>
                <Table>
                    <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>ID</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Data</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                            {/* <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>email</TableCell> */}
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Telefone</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Descrição</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Profissão</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Estado</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }} align="center">Cidade</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }} align="center">Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {budgetState.budgets && budgetState.budgets.map((budget, index) => (
                            <TableRow key={budget.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <TableCell>{budget.id}</TableCell>
                                <TableCell>
                                    {budget.createdAt ? (
                                        <Typography variant="body1" style={{ fontWeight: 'bold', color: 'red' }}>
                                            {parseDateString(budget.createdAt)?.toLocaleDateString("pt-BR")}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" style={{ fontWeight: 'bold', color: 'red' }}>
                                            Data Inválida
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>{budget.name}</TableCell>
                                {/* <TableCell>{budget.email}</TableCell> */}
                                <TableCell sx={{ minWidth: 150 }}>{budget.telephone}</TableCell>
                                <TableCell>{budget.description}</TableCell>
                                <TableCell>
                                    {budget.professionals && budget.professionals.map(professional =>
                                        professional.professions.map(profession => profession.name).join(", ")
                                    ).join(", ")}
                                </TableCell>
                                <TableCell align="center" >
                                    {budget.city ? budget.city.uf : ''}
                                </TableCell>
                                <TableCell align="center" sx={{ minWidth: 200 }} >
                                    {budget.city ? budget.city.name : ''}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        color="error"
                                        variant="contained"
                                        onClick={() => handleOpenDeleteModal(budget.id)}
                                    >
                                        Excluir
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(budgetState.total / budgetState.pageSize)}
                page={budgetState.currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: 16, marginBottom: 16 }}
            />


            <Dialog
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
            >
                <DialogTitle>Confirmação de Exclusão</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Tem certeza de que deseja excluir este orçamento? Esta ação não pode ser desfeita.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDeleteModal}
                        sx={{
                            backgroundColor: '#f0f0f0', // Cor cinza claro
                            color: '#000', // Cor do texto
                            '&:hover': {
                                backgroundColor: '#c7c7c7', // Cor de hover para o botão Cancelar
                            }
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteBudget}
                        sx={{
                            backgroundColor: '#d32f2f', // Cor vermelha
                            color: '#fff', // Cor do texto
                            '&:hover': {
                                backgroundColor: '#b71c1c', // Cor de hover para o botão Confirmar
                            }
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {loading ? <Typography>Carregando...</Typography> : pageContent}
        </BasePage>
    );
}

export default ADMListAllBudgets;
