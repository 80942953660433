import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    marginTop: 6,
                    marginBottom: 6,
                    padding: 6,
                    border: '2px solid #bdb8b8',
                    borderRadius: 4,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    marginBottom: 8,
                },
                body1: {
                    marginBottom: 8,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginTop: 20,
                },
            },
        },
    },
});

export default theme;
