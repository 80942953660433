const getStates = () => {
    return [
        { name: "AC" },
        { name: "AL" },
        { name: "AP" },
        { name: "AM" },
        { name: "BA" },
        { name: "CE" },
        { name: "DF" },
        { name: "ES" },
        { name: "GO" },
        { name: "MA" },
        { name: "MT" },
        { name: "MS" },
        { name: "MG" },
        { name: "PA" },
        { name: "PB" },
        { name: "PR" },
        { name: "PE" },
        { name: "PI" },
        { name: "RJ" },
        { name: "RN" },
        { name: "RS" },
        { name: "RO" },
        { name: "RR" },
        { name: "SC" },
        { name: "SP" },
        { name: "SE" },
        { name: "TO" },
    ]
};

const formatPhoneNumber = (value) => {
    // Remove caracteres não numéricos
    const cleaned = ('' + value).replace(/\D/g, '');

    // Aplica a máscara (XX) XXXXX-XXXX
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
};


const censoredWords = [
    'cu',
    'caga',
    'cuca',
    'fode',
    'rola',
    'xota',
    'biba',
    'puta',
    'cocô',
    'foda',
    'porra',
    'merda',
    'viado',
    'pênis',
    'bicha',
    'bosta',
    'cuzão',
    'baita',
    'broxa',
    'burro',
    'burra',
    'viadão',
    'fudido',
    'fudida',
    'vagina',
    'safado',
    'safada',
    'xereca',
    'idiota',
    'brioco',
    'xoxota',
    'pepeca',
    'pepino',
    'otário',
    'otária',
    'babaca',
    'babaca',
    'animal',
    'buceta',
    'cacete',
    'baitola',
    'cuzinho',
    'safadão',
    'safadao',
    'caralho',
    'putaria',
    'punheta',
    'boquete',
    'transar',
    'imbecil',
    'imbecil',
    'imbecil',
    'pentelho',
    'cachorro',
    'cachorra',
    'masturba',
    'viadinho',
    'masturbar',
    'mama rola',
    'arrombado',
    'arrombada',
    'punheteiro',
    'punheteira',
    'masturbação',
    'vai a merda',
    'vai se fuder',
    'vai se lascar',
    'filho da puta',
    'vai tomar no cu',
    'vai pra puta que pariu',
];

export default {
    getStates,
    censoredWords,
    formatPhoneNumber,
};
