import { combineReducers } from "redux";


import { LoginReducer } from "../pages/login";
import { HomeReducer } from "../pages/home";
import { SearchReducer } from '../pages/search';
import { BudgetReducer } from "../pages/budget";
import { ProfessionsReducer } from "../pages/profession";
import { ProfessionalReducer } from "../pages/professional";
import { BannerReducer } from "../pages/banner";


const rootReducers = combineReducers({
  homeState: HomeReducer,
  loginState: LoginReducer,
  searchState: SearchReducer,
  budgetState: BudgetReducer,
  bannerState: BannerReducer,
  professionsState: ProfessionsReducer,
  professionalState: ProfessionalReducer,
});

export default rootReducers;
