
// import { CircularProgress, Container, ThemeProvider } from '@mui/material';

// const BasePage = ({ children, loading, theme }) => {

//     return (
//         <ThemeProvider theme={theme}>
//             <Container >
//                 {loading ? (
//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
//                         <CircularProgress />
//                     </div>
//                 ) : (
//                     children
//                 )}
//             </Container>
//         </ThemeProvider>
//     );
// }

// export default BasePage;

import { CircularProgress, Container, ThemeProvider } from '@mui/material';

const BasePage = ({ children, loading, theme }) => {
    return (
        <ThemeProvider theme={theme}>
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Container
                    style={{ flex: 1 }}
                >
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        children
                    )}
                </Container>

            </div>
        </ThemeProvider>
    );
}

export default BasePage;
