import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HeadPage = ({ title }) => {

    const navigate = useNavigate();

    return (
        <Box mb={3}>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate(-1)}
                sx={{ marginBottom: 3 }}
            >
                Voltar
            </Button>
            <Typography variant="h4" align="center">
                {title}
            </Typography>
        </Box>
    )
}

export default HeadPage;