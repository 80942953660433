import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Card,
    CardContent,
    Grid,
    Container,
    IconButton,
    TextField,
    CircularProgress,
    Pagination
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';

import BasePage from '../../../../components/page_base/PageBase';

import { countProfessionalsByState, countProfessionalsByCity, setCurrentPage, setTotalProfessionals, } from '../../professional.reducer';

import theme from '../theme';
import HeadPage from '../../../../components/title/Title';

const CountProfessionalsByState = () => {
    const dispatch = useDispatch();
    const professionalState = useSelector((state) => state.professionalState);
    const [loading, setLoading] = useState(true);
    const [selectedState, setSelectedState] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [cityNameModal, setCityNameModal] = useState('');
    const [loadingContProfessionalByState, setLoadingContProfessionalByState] = useState(true);


    useEffect(() => {
        setLoading(false);
    }, []);

    const handlePageChange = (event, value) => {
        dispatch(setCurrentPage(value));
        dispatch(countProfessionalsByState(selectedState, value, professionalState.pageSize));
    };

    const handleStateChange = (event) => {
        setLoading(true);
        const state = event.target.value;
        setSelectedState(state);
        dispatch(setCurrentPage(1));
        dispatch(setTotalProfessionals(0));
        dispatch(countProfessionalsByState(state, professionalState.currentPage, professionalState.pageSize))
            .then(() => {
                setLoading(false);
            });
    };

    const handleSearchCity = (event) => {
        setSearchCity(event.target.value);
    };

    const handleShowModal = (item) => {
        setCityNameModal(item.cidade);
        setShowModal(true); // Move isso para cá para garantir que a modal abra apenas após a atualização
        setLoadingContProfessionalByState(true);
        dispatch(countProfessionalsByCity(item.cidadeId)).then(() => {
            setLoadingContProfessionalByState(false);
        });
    };

    const handleCloseModal = () => {
        setLoadingContProfessionalByState(false);
        setShowModal(false);

    };

    const filteredProfessionals = professionalState.professionalsByState && professionalState.professionalsByState.filter(item =>
        item.cidade.toLowerCase().includes(searchCity.toLowerCase())
    );

    const page = (
        <Container>
            <HeadPage title={`Contagem de Profissionais por Estado`} />

            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <FormControl fullWidth required variant="outlined">
                                <InputLabel id="select-estado-label" shrink={true}>
                                    Selecione o Estado
                                </InputLabel>
                                <Select
                                    labelId="select-estado-label"
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    label="Selecione o Estado"
                                    required
                                >
                                    <MenuItem value="">
                                        <em>Estado</em>
                                    </MenuItem>
                                    {professionalState.states && professionalState.states.map((state, index) => (
                                        <MenuItem key={index} value={state.name}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <PeopleIcon style={{ fontSize: '2rem', color: theme.palette.error.main }} />
                                <Typography variant="h6" color="error">
                                    Total de Cidades: {professionalState.total}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                        label="Pesquisar por cidade"
                        variant="outlined"
                        fullWidth
                        value={searchCity}
                        onChange={handleSearchCity}
                        style={{ marginBottom: '16px' }}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>ID</TableCell>
                                    <TableCell variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>Cidade</TableCell>
                                    <TableCell variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }} align="center">Quantidade de Profissionais</TableCell>
                                    <TableCell variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }} align="center">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredProfessionals.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan="4" align="center">
                                            Nenhum registro encontrado!
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    filteredProfessionals.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.cidadeId}</TableCell>
                                            <TableCell>{item.cidade}</TableCell>
                                            <TableCell align="center">{item.quantidadeProfissionais}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" color="error" onClick={() => handleShowModal(item)} title='Ver a quantidade de profissionais'>
                                                    <SearchIcon style={{ marginLeft: 5 }} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        <Pagination
                            count={Math.ceil(professionalState.total / professionalState.pageSize)}
                            page={professionalState.currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            style={{ marginTop: 16, marginBottom: 16 }}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <Modal open={showModal} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    maxWidth: '90vw', // Define a largura máxima para a modal
                    maxHeight: '90vh', // Define a altura máxima para a modal
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto',
                }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6">Profissões em {cityNameModal}</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Profissão</TableCell>
                                    <TableCell align="center">Quantidade</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loadingContProfessionalByState ? (
                                    <TableRow>
                                        <TableCell colSpan="2" align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    professionalState.quantityProfessionalsByCity && professionalState.quantityProfessionalsByCity.length > 0 ? (
                                        professionalState.quantityProfessionalsByCity.map((prof, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{prof.professionName}</TableCell>
                                                <TableCell align="center" style={{ color: 'red', fontWeight: 'bold' }}>
                                                    {prof.quantity}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan="2" align="center">
                                                Nenhum profissional cadastrado!
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2} textAlign="center">
                        <Button variant="contained" color="error" onClick={handleCloseModal}>
                            Fechar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
};

export default CountProfessionalsByState;
