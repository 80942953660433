import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography, Box, IconButton, ThemeProvider, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import BasePage from '../../../../components/page_base/PageBase';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';

import { loadCities, loadProfessions, sendDataForm, setErrorMessage, setSuccessMessage } from '../../banner.reducer';

import theme from '../theme';
import HeadPage from '../../../../components/title/Title';

const BannerFormPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bannerState = useSelector((state) => state.bannerState);

    const [uf, setUf] = useState('');
    const [cityId, setCityId] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [professionsSelected, setProfessionsSelected] = useState([]);
    const [accessLink, setAccessLink] = useState(''); // Novo estado para o link de acesso

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        dispatch(loadProfessions());
    }, [dispatch]);

    const handleFileUpload = (acceptedFiles) => {
        setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    };

    const handleRemoveFile = (fileToRemove, event) => {
        event.stopPropagation(); // Impede que o evento de clique se propague para o dropzone
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            cityId,
            accessLink,
            file: selectedFiles,
            professions: professionsSelected,
        }

        dispatch(sendDataForm(data)).then(() => {
            setLoading(false);  // Certifique-se de definir o loading como false após a conclusão
        })

    };

    const onChangeState = (e) => {
        setUf(e.target.value);
        dispatch(loadCities(e.target.value));
    };

    const handleProfessionChange = (id) => {
        setProfessionsSelected((prev) => {
            if (prev.includes(id)) {
                return prev.filter((selectedId) => selectedId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileUpload,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/gif': []
        }
    });

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    return (
        <ThemeProvider theme={theme}>
            <BasePage
                theme={theme}
                loading={loading}
            >
                <HeadPage
                    title="Cadastrar Banner"
                />

                {bannerState.errorMessage && (
                    <MakeMessage
                        typeMessage="error"
                        message={bannerState.errorMessage}
                        onClose={onCloseMessage}
                    />
                )}

                {bannerState.successMessage && (
                    <MakeMessage
                        typeMessage="success"
                        message={bannerState.successMessage}
                        onClose={onCloseMessage}
                    />
                )}

                <form onSubmit={handleSubmit} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <FormControl fullWidth required>
                            <InputLabel id="estado-label">Estado</InputLabel>
                            <Select
                                labelId="estado-label"
                                value={uf}
                                onChange={onChangeState}
                                label="Estado"
                                name="uf"
                            >
                                <MenuItem value="">
                                    <em>Estado</em>
                                </MenuItem>
                                {bannerState.states && bannerState.states.map((state, index) => (
                                    <MenuItem key={index + 1} value={state.name}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel id="cidade-label">Cidade</InputLabel>
                            <Select
                                labelId="cidade-label"
                                value={cityId}
                                onChange={(e) => setCityId(e.target.value)}
                                label="Cidade"
                                name="cidadeId"
                            >
                                <MenuItem value="">
                                    <em>Cidade</em>
                                </MenuItem>
                                {bannerState.cities && bannerState.cities.map((city) => (
                                    <MenuItem key={city.id} value={city.id}>
                                        {city.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                        <FormControl fullWidth required component="fieldset">
                            <Typography variant="body1">Selecione as profissões que serão apresentadas no Banner</Typography>
                            <Grid container spacing={2}>
                                {bannerState.professions && bannerState.professions.map((profession) => (
                                    <Grid item xs={12} sm={6} md={4} key={profession.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={professionsSelected.includes(profession.id)}
                                                    onChange={() => handleProfessionChange(profession.id)}
                                                    name={profession.name}
                                                />
                                            }
                                            label={profession.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormControl>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Link de Acesso"
                            variant="outlined"
                            value={accessLink}
                            onChange={(e) => setAccessLink(e.target.value)}

                        />
                    </Box>

                    <Box {...getRootProps()} sx={{ border: '2px dashed #cccccc', padding: 2, textAlign: 'center', mt: 2 }}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <Typography variant="body2">Solte os arquivos aqui...</Typography>
                        ) : (
                            <Typography variant="body2">Arraste e solte os arquivos aqui, ou clique para selecionar arquivos</Typography>
                        )}
                        <Box mt={2} display="flex" flexWrap="wrap" gap={2} sx={{ position: 'relative' }}>
                            {selectedFiles.map((file, index) => (
                                <Box key={index} sx={{ position: 'relative' }}>
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`preview-${index}`}
                                        style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 8 }}
                                    />
                                    <IconButton
                                        sx={{ position: 'absolute', top: -25, right: -25, color: 'red' }}
                                        onClick={(event) => handleRemoveFile(file, event)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>



                    <Box sx={{ display: 'flex', justifyContent: 'center', m: 5 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ alignSelf: 'center' }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Cadastrar'}
                        </Button>
                    </Box>
                </form>
            </BasePage>
        </ThemeProvider>
    );
};

export default BannerFormPage;
