import FormProfessional from './pages/form/FormProfessional';
import LastProfessionals from './pages/list/LastProfessionals';
import FindAllProfessionals from './pages/list/FindAllProfessionals';
import CountProfessionalsByProfession from './pages/list/CountProfessionalsByProfessions';
import CountProfessionalsByState from './pages/list/CountProfessionalsByState';



import ProfessionalReducer from './professional.reducer';

export {
    FormProfessional,
    LastProfessionals,
    FindAllProfessionals,
    CountProfessionalsByState,
    CountProfessionalsByProfession,
    ProfessionalReducer
}