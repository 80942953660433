export const parseDateString = (dateString) => {
    // Usando expressão regular para extrair a data
    const regex = /time\.Date\((\d+),\s*([^,]+),\s*(\d+),\s*(\d+),\s*(\d+),\s*(\d+),\s*\d+,\s*time\.\w+\)/;
    const matches = regex.exec(dateString);

    if (matches) {
        const year = matches[1];
        const month = new Date(Date.parse(matches[2] + " 1, 2021")).getMonth(); // Obtém o índice do mês
        const day = matches[3];
        const hours = matches[4];
        const minutes = matches[5];
        const seconds = matches[6];

        return new Date(year, month, day, hours, minutes, seconds);
    }
    return null; // Se não conseguir extrair, retorna null
};