import { createSlice } from "@reduxjs/toolkit";


const initialState = {

}

const HomeSlice = createSlice({
    name: "homeState",
    initialState,
    reducers: {

    }
});

export const { setProfessions } = HomeSlice.actions;



export default HomeSlice.reducer;