import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import theme from './theme';
import BasePage from '../../../components/page_base/PageBase';
import MakeMessage from '../../../components/makeMessage/MakeMessage';

import { confirmPassword, setErrorMessage, setSuccessMessage } from '../login.reducer';

const ConfirmPasswordPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();  // Add useLocation to get the current location

    const loginState = useSelector((state) => state.loginState);

    const [token, setToken] = useState("");  // State to store the token
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShownewPassword] = useState(false);

    useEffect(() => {
        setLoading(false);
        // Extract token (email) from URL
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            setToken(token);
        }
    }, [loading, location.search]);

    const onChangePasswordHandler = (e) => {
        setPassword(e.target.value);
    };

    const onChangeNewPasswordHandler = (e) => {
        setNewPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowNewPassword = () => {
        setShownewPassword(!showConfirmPassword);
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        if (password !== newPassword) {
            dispatch(setErrorMessage("As senhas não coincidem"));
            return;
        }
        setLoading(true);
        dispatch(confirmPassword(token, password))
            .then(() => {
                setLoading(false);
                navigate('/login'); // Redirecionar para a página de login após redefinir a senha
            });

    };

    const page = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    p: 2,
                }}
            >
                <Box sx={{ mt: 4, mb: 12, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Redefinir Senha
                    </Typography>

                    {loginState.errorMessage && (
                        <MakeMessage
                            typeMessage="error"
                            message={loginState.errorMessage}
                            onClose={onCloseMessage}
                        />
                    )}

                    {loginState.successMessage && (
                        <MakeMessage
                            typeMessage="success"
                            message={loginState.successMessage}
                            onClose={onCloseMessage}
                        />
                    )}

                    <form onSubmit={onSubmitForm}>
                        <TextField
                            id="password"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            required
                            value={password}
                            onChange={onChangePasswordHandler}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            id="new-password"
                            label="Confirmar Senha"
                            type={showConfirmPassword ? "text" : "password"}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            required
                            value={newPassword}
                            onChange={onChangeNewPasswordHandler}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowNewPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            sx={{ mt: 2 }}
                        >
                            Redefinir
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default ConfirmPasswordPage;
