import { Box, Grid, ThemeProvider, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search'; // Importing the search icon
import theme from "./theme";

const ListSixProfessions = ({ data, title }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ ...theme.components.MuiBox.styleOverrides.root }}>
                <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                    <Typography variant="h3" component="h2" gutterBottom sx={{ textAlign: 'center' }}>
                        {title}
                    </Typography>
                    <MuiLink
                        component={Link}
                        to="/list-professions"
                        sx={{
                            textDecoration: 'none',
                            position: { xs: 'relative', md: 'absolute' },
                            right: { md: 0 },
                            top: { xs: 'auto', md: '50%' },
                            transform: { xs: 'none', md: 'translateY(-50%)' },
                            marginTop: { xs: '16px', md: 0 }, // Add margin on mobile to create space
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: { xs: 'center', md: 'flex-start' }, // Center align on mobile
                            width: { xs: '100%', md: 'auto' } // Full width on mobile
                        }}
                    >
                        <SearchIcon sx={{ marginRight: '5px' }} />
                        Ver mais profissões
                    </MuiLink>
                </Box>

                <Grid container spacing={10}>
                    {data &&
                        data.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                                <Box
                                    sx={{
                                        padding: 2,
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid black',
                                        borderRadius: '10px',
                                        flex: 1,
                                        boxShadow: '0 20px 20px -4px rgba(9, 101, 238, 0.6)',
                                        transition: 'transform 0.3s ease-in-out',
                                        marginBottom: '30px', // Added margin bottom to each Box
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    <Link to={`/search-professionals?profession=${item.name}`} style={{ textDecoration: 'none' }}>
                                        <img src={item.imageUrl} alt={item.name} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                                    </Link>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default ListSixProfessions;
